<template>
  <div>
    <div class="container" style="margin-top: 50px">
      <el-empty v-show="exams.length===0" description="该课程还没有考试"></el-empty>
      <el-row v-for="exam in exams" :key="exam.id">
        <el-col :lg="6" :md="12" :xs="24">
          <el-card><h3>{{ exam.name }}</h3>
            <p>开始时间: {{ exam.start }}</p>
            <p>结束时间: {{ exam.finalGetTime }}</p>
            <p>考试时间: {{ exam.duration }} 分钟</p>
            <el-divider></el-divider>
            <div style="text-align: center">
              <div v-if="exam.score!==null" class="score">
                <span v-if="exam.score.status==='inExam'"><el-button
                  @click="startExam(exam.id,exam.duration)">进入考试</el-button></span>
                <span v-else-if="exam.score.status==='checking'">改卷中</span>
                <span v-else-if="exam.score.status==='checked'">得分：{{ exam.score.result }} <el-button
                  type="info">查看试卷</el-button></span>
              </div>
              <el-button v-else-if="exam.score===null&&beforeNow(exam.finalGetTime)" type="info" float="center"
                         @click="checkMyPaper(exam.id)">
                已结束,查看试卷
              </el-button>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <div class="backgroundPic">
      <img src="https://cos.mooc.huangzhaokun.top/moocImg/undraw_certificate_re_yadi.svg"
           alt="">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AllMyExams',
  data () {
    return {
      termId: 0,
      exams: [],
      currentTime: ''
    }
  },
  created () {
    this.termId = this.$route.params.termId
    this.showExams()
    this.currentTime = new Date().toLocaleString()
    console.log(this.currentTime)
    // this.compareTime('2022-04-04 06:00:00')
  },
  methods: {
    checkMyPaper (examId) {
      this.$router.push(
        {
          name: 'FinishedExam',
          params: {
            examId: examId
          }
        }
      )
    },
    beforeNow (time) {
      const now = new Date()
      const timeFormat = new Date(time)
      return timeFormat.getTime() < now.getTime()
    },
    startExam (id, duration) {
      this.$confirm('是否进入考试?考试时间为' + duration + '分钟!中途不得退出')
        .then(_ => {
          this.$router.push({
            name: 'Exam',
            params: { examId: id }
          })
        })
        .catch(_ => {
        })
    },
    showExams () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/exam/showExamsByTermId',
        params: {
          termId: this.termId,
          currentPage: 1,
          pageSize: 10
        }
      }).then(res => {
        if (res.status === 200) {
          if (res.data.status) {
            this.exams = res.data.data.content
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.backgroundPic {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.backgroundPic img {
  width: 400px;
  height: auto;
}

.score span {
  margin-bottom: 5px;
  font-size: 20px;
}
</style>
